import Header from "../components/Header";
import React from "react";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Header />
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            {/* Image Section */}
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden h-100"
                style={{ minHeight: 400 }}
              >
                <img
                  className="position-absolute w-100 h-100 pt-5 pe-5"
                  src="assets/img/about_1.jpg"
                  alt="About Image 1"
                  style={{ objectFit: "cover" }}
                />
                <img
                  className="position-absolute top-0 end-0 bg-white ps-2 pb-2"
                  src="assets/img/about-2.jpg"
                  alt="About Image 2"
                  style={{ width: 200, height: 200 }}
                />
              </div>
            </div>
            {/* Text Section */}
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                  {t("/about-us.page-title")}
                </div>
                <h1 className="display-6 mb-5">{t("aboutPage.heding_main")}</h1>
                <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                  <p className="text-dark mb-2">{t("aboutPage.heding")}</p>
                  <span className="text-primary">
                    {t("aboutPage.amit_mohite")}
                  </span>
                </div>
                <p className="mb-5" align="justify">
                  {t("aboutPage.aman")}
                </p>
                <a className="btn btn-primary py-2 px-3 me-3" href="">
                  {t("servicesPage.Learn_More")}
                  <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </a>
                <a
                  className="btn btn-outline-primary py-2 px-3"
                  href="/contact-us"
                >
                  {t("/contact-us.page-title")}
                  <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Challenges and Quotes Section */}
        <div className="mt-5">
          <p className="fs-5 mb-5 animated slideInDown">
            <strong>{t("aboutPage.Challenges1")}</strong>
            <span>{t("aboutPage.Challenges_p1")}</span>
          </p>
          <p className="fs-5 mb-5 animated slideInDown">
            <strong>{t("aboutPage.Challenges2")}</strong>
            <span>{t("aboutPage.Challenges_p2")}</span>
          </p>

          {/* Amrapali Quote Section with Image */}
          <div className="row g-5 align-items-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <img
                className="img-fluid rounded"
                src="assets/img/amrpali.png"
                alt="Amrapali"
                style={{
                  objectFit: "cover",
                  transition: "transform 0.3s",
                  transform: "scale(1)",
                  objectFit: "cover",
                  height: "500px",
                  width: "100%",
                }}
              />
            </div>
            <div
              className="col-lg-8 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <h3>
                <strong>
                  <i>{t("aboutPage.quotes_Amrapali")}</i>
                  <br />
                  <u>{t("aboutPage.Amrapali_mohite")}</u>
                </strong>
              </h3>
            </div>
          </div>

          {/* Foundation and Impact Section */}
          <p className="fs-5 mb-5 animated slideInDown">
            <strong>{t("aboutPage.Foundation")}</strong>
            {t("aboutPage.Foundation_p")}
          </p>
          <p className="fs-5 mb-5 animated slideInDown">
            <strong>{t("aboutPage.Impact")}</strong>
            <br />
            {t("aboutPage.Impact_1")}
            <br />
            {t("aboutPage.Impact_2")}
            <br />
            {t("aboutPage.Impact_3")}
            <br />
            {t("aboutPage.Impact_4")}
            <br />
            {t("aboutPage.Impact_5")}
            <br />
            {t("aboutPage.Impact_6")}
          </p>
        </div>
      </div>
      {/* About End */}
    </>
  );
}
