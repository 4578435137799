import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import ContactInfo from "./../data/contact-data.json";

import React, { Component, Suspense } from "react";
import { useTranslation, withTranslation, Trans } from "react-i18next";
export default function Footer() {
  const pathname = window.location.pathname;
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log(i18n.language);
  };
  const currentYear = new Date().getFullYear();
  let tYear ='';
  // Format the current year in different languages
  const options = { year: 'numeric' };
  const yearInHindi = new Intl.DateTimeFormat('hi-IN', options).format(new Date(currentYear, 0));
  console.log(yearInHindi)
  const yearInMarathi = new Intl.DateTimeFormat('mr-IN', options).format(new Date(currentYear, 0));
  if(i18n.language == 'mr'){
    tYear = yearInMarathi;
  }
  else if(i18n.language == 'hn') {
    tYear = yearInHindi;
  }
  else {
    tYear=currentYear;
  }
  return (
    <div
      className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h2 className="fw-bold text-primary mb-4">
              {t("sawli")} <span className="text-white">{t("foundation")}</span>
            </h2>
            <p>{t("savli_foundation")} </p>
            <div className="d-flex pt-2">
              <a className="btn btn-square me-1" href={t("twitter")}>
                <i className="fab fa-twitter" />
              </a>
              <a
                className="btn btn-square me-1"
                href="https://www.facebook.com/profile.php?id=61552780002251"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a className="btn btn-square me-1" href="/youtube">
                <i className="fab fa-youtube" />
              </a>
              <a className="btn btn-square me-0" href={t("linkedin")}>
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">{t("Address")}</h5>
            <p>
              <i className="fa fa-map-marker-alt me-3" />
              {t("address")}
            </p>
            <p>
              <i className="fa fa-phone-alt me-3" />
              {t("phone1")}
            </p>
            <p>
              <i className="fa fa-envelope me-3" />
              {t('emailInfo')}
            </p>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">{t("Quick Links")}</h5>
            <Link className="btn btn-link" to="/">
              {t("home")}
            </Link>
            <Link className="btn btn-link" to="/about-us">
              {t("/about-us.page-title")}
            </Link>
            <Link className="btn btn-link" to="/causes">
              {t("/causes.page-title")}
            </Link>
            <Link className="btn btn-link" to="/our-services">
              {t("/our-services.page-title")}
            </Link>
            <Link className="btn btn-link" to="/contact-us">
              {t("/contact-us.page-title")}
            </Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-light mb-4">{t("Newsletter")}</h5>
            <p> {t("Subscribe")} </p>
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: 400 }}
            >
              <input
                className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                {t("SignUp")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              {t("Copyrights")} ©  {tYear} .{" "}
              {t("All rights reserved by")} - {t('sawli')} {t('foundation')}
              {/* © <a href="#">Your Site Name</a>, All Right Reserved. */}
            </div>
            <div className="col-md-6 text-center text-md-end">
              Developed By{" "}
              <a href="https://gammaverse.in">GammaVerse Pvt. Ltd.</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link
      to={to}
      {...props}
      className={isActive ? "nav-item nav-link active" : "nav-item nav-link"}
    >
      {children}
    </Link>
  );
}
