import { useParams, Link } from "react-router-dom";
import Title from "./../data/title.json";
import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
export default function Header() {
  const pathname = window.location.pathname;
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
    <Suspense>
      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            {t(pathname+'.page-title')}
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  {t('home')}
                </Link>
              </li>
              {/* <li className="breadcrumb-item">
            <a className="text-white" href="#">
              Pages
            </a>
          </li> */}
              <li className="breadcrumb-item text-primary active" aria-current="page">
                {t(pathname+'.page-title')}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      </Suspense>
    </>
  );
}
