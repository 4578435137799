import Header from "../components/Header";
import React from "react";

import { useTranslation } from "react-i18next";

export default function Causes() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <Header />
      {/* Causes Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 500 }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              {t("causesPage.Feature")}
            </div>
            <h1 className="display-6 mb-5">{t("causesPage.Learn")}</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ></div>

            {/* counter start */}
            <div class="container my-5 ">
              <div class="row text-center">
                <div class="col-md-4 mb-4">
                  <div class="icon icon-shape icon-shape-primary rounded-circle me-2 me-md-0 mb-2 ">
                    <span class="far fa-grin-hearts"></span>
                  </div>
                  <span class="counter display-3 text-primary d-block">
                    600
                  </span>
                  <span class="h5 text-gray"> {t("causesPage.Happy")} </span>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="icon icon-shape icon-shape-secondary rounded-circle me-2 me-md-0 mb-2">
                    <span class="fas fa-box-open"></span>
                  </div>
                  <span class="counter display-3 text-primary d-block">
                    153
                  </span>
                  <span class="h5 text-gray"> {t("causesPage.Achievements")}</span>
                </div>
                <div class="col-md-4 mb-4">
                  <div class="icon icon-primary me-2 me-md-0 mb-4">
                    <span class="fas fa-brain"></span>
                  </div>
                  <span class="counter display-3 text-primary d-block">
                    800
                  </span>
                  <span class="h5 text-gray">{t("causesPage.People")}</span>
                </div>
              </div>
            </div>
            {/* Image Gallery Start */}
            <div className="container my-5">
              <h2 className="text-center mb-4"> {t("causesPage.Gallery")}</h2>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="4"
                    aria-label="Slide 5"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="5"
                    aria-label="Slide 6"
                  ></button>
                </div>

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="assets/img/Gallery-1.jpg"
                      className="d-block w-100 img-fluid"
                      alt="Gallery Image 1"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="assets/img/Gallery-2.jpg"
                      className="d-block w-100 img-fluid"
                      alt="Gallery Image 2"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="assets/img/Gallery-3.jpg"
                      className="d-block w-100 img-fluid"
                      alt="Gallery Image 3"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="assets/img/Gallery-4.jpg"
                      className="d-block w-100 img-fluid"
                      alt="Gallery Image 4"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="assets/img/Gallery-5.png"
                      className="d-block w-100 img-fluid"
                      alt="Gallery Image 5"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="assets/img/Gallery-6.png"
                      className="d-block w-100 img-fluid"
                      alt="Gallery Image 6"
                    />
                  </div>
                </div>

                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            {/* Gallery End */}

            {/* Random Img Gallery */}
            <div className="container my-5">
              <h2 className="text-center mb-4"> {t("causesPage.Memorable")}</h2>
              <div className="row g-4">
                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_1.png"
                      className="img-fluid card-img-top"
                      alt="Random Place 1"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_2.png"
                      className="img-fluid card-img-top"
                      alt="Random Place 2"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_3.png"
                      className="img-fluid card-img-top"
                      alt="Random Place 3"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_4.png"
                      className="img-fluid card-img-top"
                      alt="Random Place 4"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_5.jpg"
                      className="img-fluid card-img-top"
                      alt="Random Place 5"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_6.jpg"
                      className="img-fluid card-img-top"
                      alt="Random Place 6"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_7.jpg"
                      className="img-fluid card-img-top"
                      alt="Random Place 7"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_8.jpg"
                      className="img-fluid card-img-top"
                      alt="Random Place 8"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <img
                      src="assets/img/sawli_foundetion_9.jpg"
                      className="img-fluid card-img-top"
                      alt="Random Place 9"
                      style={{
                        transition: "transform 0.3s",
                        transform: "scale(1)",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Random Img  end */}
          </div>
        </div>
      </div>
      {/* Causes End */}
    </>
  );
}
