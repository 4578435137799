import "./assets/lib/animate/animate.min.css";
import "./assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
// Import Pages and Components
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Causes from "./pages/Causes";
import Contact from "./pages/Contact";
import Service from "./pages/Service ";
import Donate from "./pages/Donate";
import PageNotFound from "./pages/PageNotFound";
import {
  useParams,
  Route,
  Link,
  Routes,
  BrowserRouter,
} from "react-router-dom";
function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/causes" element={<Causes />} />
          <Route path="/our-services" element={<Service />} />
          <Route path="/contact-us" element={<Contact />} />

          <Route path="/donate-now" element={<Donate />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
