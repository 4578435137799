import React, { Component, Suspense } from "react";
import { useTranslation, withTranslation, Trans } from "react-i18next";

export default function Home() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Suspense>
        {/* Carousel Start */}
        <div className="container-fluid p-0 mb-5">
          <div
            id="header-carousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="w-100"
                  src="assets/img/home-1.png"
                  alt="Image"
                />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 pt-5">
                        <h1 className="display-4 text-white mb-3 animated slideInDown">
                          {t("homePage.heading")}
                        </h1>
                        <p className="fs-5 text-white-50 mb-5 animated slideInDown">
                          {t("homePage.slogn")}
                        </p>
                        <a
                          className="btn btn-primary py-2 px-3 animated slideInDown"
                          href="/about-us"
                        >
                          {t("servicesPage.Learn_More")}

                          <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                            <i className="fa fa-arrow-right" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  className="w-100"
                  src="assets/img/home-2.png"
                  alt="Image"
                />
                <div className="carousel-caption">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-7 pt-5">
                        <h1 className="display-4 text-white mb-3 animated slideInDown">
                          {t("homePage.heading1")}{" "}
                        </h1>
                        <p className="fs-5 text-white-50 mb-5 animated slideInDown">
                          {t("homePage.slogn_help")}
                        </p>
                        <a
                          className="btn btn-primary py-2 px-3 animated slideInDown"
                          href="/about-us"
                        >
                          {t("servicesPage.Learn_More")}

                          <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                            <i className="fa fa-arrow-right" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </Suspense>

      <div className="container-xxl bg-light my-5 py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 500 }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              News
            </div>
            <h1 className="display-6 mb-5">
              Every Child Deserves The Opportunity To Learn
            </h1>
          </div>

          <div className="row g-4 justify-content-center">
            {/* News  1 */}
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="news-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                    <small>Latest News</small>
                  </div>
                  <h5 className="mb-3">The Indian Express</h5>
                  <p>
                    Transgender person runs ‘footpath schools’ in Pune to
                    transform street children’s lives While she works at an NGO,
                    Amrapali has also established her own organisation, Savali
                    Social Foundation, through which she undertakes multiple
                    activities for deprived sections of society
                  </p>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="assets/img/news-time-1.webp"
                    alt="more......"
                  />
                  <div className="news-overlay">
                    <a
                      className="btn btn-outline-primary"
                      href="https://indianexpress.com/article/cities/pune/transgender-person-footpath-schools-pune-transform-street-children-lives-8971328/"
                    >
                      {t("servicesPage.Learn_More")}
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* News  2 */}
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="news-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                    <small>Latest News</small>
                  </div>
                  <h5 className="mb-3">Pune Times Mirror</h5>
                  <p>
                    Club comes to the aid of school run by transgenders on
                    footpath Sawali Foundation & rsquo;s drive gathers momentum;
                    receives donations in the form of uniforms, books etc
                  </p>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="assets/img/news-mirar-2.jpg"
                    alt="more......"
                  />
                  <div className="news-overlay">
                    <a
                      className="btn btn-outline-primary"
                      href="https://punemirror.com/pune/others/Club-comes-to-the-aid-of-school-run-by-transgenders-on-foot/cid8459264.htm"
                    >
                      {t("servicesPage.Learn_More")}
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* News  3 */}
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="news-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                    <small>Latest News</small>
                  </div>
                  <h5 className="mb-3">Punekar News Today</h5>
                  <p>
                    Transgender social worker, Amit Mohite (29), has started a
                    'footpath school' near Pune Railway Station for children
                    living on the footpath. He started the school to educate
                    these children for free and meet their daily basic needs
                    through his 'Sawali Social Foundation, a non-profit
                    organization.
                  </p>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="assets/img/news-pune-3.jpg"
                    alt="more......"
                  />
                  <div className="news-overlay">
                    <a className="btn btn-outline-primary" href="">
                      {t("servicesPage.Learn_More")}
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Video Section */}
            <div
              className="col-lg-12 col-md-12 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="video-item bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                    <small>Video</small>
                  </div>
                  <h5 className="mb-3">Watch Our Journey</h5>
                  <p>
                    "Amartaali" is a documentary film that tells the real-life
                    story of Amit Mohite, a transgender individual, in the
                    context of the challenges faced by the transgender and LGBT
                    community in Indian society. Despite the discrimination and
                    ridicule he encounters, Amit dedicates himself to helping
                    the less fortunate. He offers free education and food to
                    impoverished children living on the streets, helping them
                    gain access to better opportunities. Amit also founded the
                    Sawali Social Foundation and has facilitated the education
                    of 135 children, supported 45 through his own resources, and
                    made a positive impact on society. He extends his assistance
                    to disabled individuals, aids those involved in
                    prostitution, and supports other transgender people.
                  </p>
                </div>
                <div
                  className="position-relative mt-auto"
                  style={{
                    paddingBottom: "56.25%",
                    position: "relative",
                    height: "0",
                  }}
                >
                  <iframe
                    className="w-100 h-100"
                    src="https://www.youtube.com/embed/eg4V6u4gYE4?si=xCOMBOzl_s5msY76"
                    title="Watch Our Journey"
                    allowFullScreen
                    loading="lazy"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Causes End */}
      {/* Donate Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                {t("/donate-now.page-title")}
              </div>
              <h1 className="display-6 mb-5">{t("donatePage.thank")}</h1>
              <p className="mb-0">{t("donatePage.thanks_for")}</p>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="h-100 bg-secondary p-5">
                <form>
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="name"
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control bg-light border-0"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="contact"
                          placeholder="Your Contact Number"
                        />
                        <label htmlFor="contact">Your Contact Number</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control bg-light border-0"
                          id="amount"
                          placeholder="Donation Amount"
                          min="0"
                        />
                        <label htmlFor="amount">Donation Amount (rs)</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary px-5"
                        href="/donate-now"
                        style={{ height: 60 }}
                        onClick={"/donate-now"}
                      >
                        Donate Now
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                          <i className="fa fa-arrow-right" />
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Donate End */}
    </>
  );
}
