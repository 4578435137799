import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import ContactInfo from "./../data/contact-data.json";
import React, { Component, Suspense } from "react";
import { useTranslation, withTranslation, Trans } from "react-i18next";

export default function Navbar() {
  const phoneOne = "tel:" + ContactInfo.phone1;
  const phoneTwo = "tel:" + ContactInfo.phone2;
  const emailInfo = "mailto:" + ContactInfo["emailInfo"];
  const emailSupport = "mailto:" + ContactInfo["emailSupport"];
  const social = ContactInfo.social;

  const pathname = window.location.pathname;
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Suspense>
        {/* Navbar Start */}
        <div
          className="container-fluid fixed-top px-0 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
              <small>
                <i className="fa fa-map-marker-alt me-2" />
                {ContactInfo.shortAddress}
              </small>
              <small className="ms-4">
                <i className="fa fa-envelope me-2" />
                {ContactInfo.emailInfo}
              </small>
            </div>
            <div className="col-lg-6 px-5 text-end">
              <small>{t("select-language")}:</small>
              <button
                className="btn btn-outline-primary btn-sm lang-btn"
                type="button"
                onClick={() => changeLanguage("hn")}
              >
                हिंदी
              </button>
              <button
                className="btn btn-outline-primary btn-sm lang-btn"
                type="button"
                onClick={() => changeLanguage("mr")}
              >
                मराठी
              </button>
              <button
                className="btn btn-outline-primary btn-sm lang-btn"
                type="button"
                onClick={() => changeLanguage("en")}
              >
                English
              </button>

              {/* </div>
            <div className="col-lg-3 px-5 text-end"> */}
              <small>{t("follow-us")}:</small>
              <a className="text-white-50 ms-3" href={t("facebook")}>
                <i className="fab fa-facebook-f" />
              </a>
              <a className="text-white-50 ms-3" href={t("twitter")}>
                <i className="fab fa-twitter" />
              </a>
              <a className="text-white-50 ms-3" href={t("linkedin")}>
                <i className="fab fa-linkedin-in" />
              </a>
              <a className="text-white-50 ms-3" href={t("instagram")}>
                <i className="fab fa-instagram" />
              </a>
            </div>
          </div>

          <nav
            className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn"
            data-wow-delay="0.1s"
          >
            <a href="/" className="navbar-brand ms-4 ms-lg-0">
              <h1 className="fw-bold text-primary m-0">
                {t("sawli")}{" "}
                <span className="text-white">{t("foundation")}</span>
              </h1>
            </a>
            <button
              type="button"
              className="navbar-toggler me-4"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto p-4 p-lg-0">
                <CustomLink to="/" className="nav-item nav-link">
                  {t("home")}
                </CustomLink>
                <CustomLink to="/about-us" className="nav-item nav-link">
                  {t("/about-us.page-title")}
                </CustomLink>
                <CustomLink to="/causes" className="nav-item nav-link">
                  {t("/causes.page-title")}
                </CustomLink>
                <CustomLink to="/our-services" className="nav-item nav-link">
                  {t("/our-services.page-title")}
                </CustomLink>
                <CustomLink to="/contact-us" className="nav-item nav-link">
                  {t("/contact-us.page-title")}
                </CustomLink>
              </div>
              <div className="d-none d-lg-flex ms-2">
                <a
                  className="btn btn-outline-primary py-2 px-3"
                  href="/donate-now"
                >
                  {t("/donate-now.page-title")}
                  <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </a>
              </div>
            </div>
          </nav>
        </div>
        {/* Navbar End */}
      </Suspense>
    </>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link
      to={to}
      {...props}
      className={isActive ? "nav-item nav-link active" : "nav-item nav-link"}
    >
      {children}
    </Link>
  );
}
