import Header from "../components/Header";
import Footer from "../components/Footer";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Donate() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Header />
      {/* Donate Start */}
      <div className="container-fluid py-5">
        <div className="container">
          {/* QR Code Section */}
          <div className="row g-5 align-items-center mb-5">
            <div className="col-lg-6 text-center">
              <img
                src="qr /assets/img/aman_qr.jpeg"
                alt="QR Code"
                className="img-fluid"
                style={{ maxWidth: "200px" }}
              />
              <p className="mt-2">{t("donatePage.QR")}</p>
            </div>
            <div className="col-lg-6">
              <h5 className="mt-3">Bank Details:</h5>
              <p>Account Holder: SAWALI FOUNDETION</p>
              <p>Account Number: 7721966231</p>
              <p>IFSC Code: BOI0004546</p>
              <p>Bank Name: BOI</p>
            </div>
          </div>

          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                {t("/donate-now.page-title")}
              </div>
              <h1 className="display-6 mb-5">{t("donatePage.thank")} </h1>
              <p className="mb-0">{t("donatePage.thanks_for")}</p>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div className="h-100 bg-secondary p-5">
                <form>
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="name"
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control bg-light border-0"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          id="contact"
                          placeholder="Your Contact Number"
                        />
                        <label htmlFor="contact">Your Contact Number</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control bg-light border-0"
                          id="amount"
                          placeholder="Donation Amount"
                          min="0"
                        />
                        <label htmlFor="amount">Donation Amount (rs)</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="file"
                          className="form-control bg-light border-0"
                          id="receipt"
                          placeholder="Upload Transaction Receipt"
                        />
                        <label htmlFor="receipt">
                          Upload Transaction Receipt
                        </label>
                      </div>
                    </div>

                    <div className="col-12">
                      <button
                        className="btn btn-primary px-5"
                        style={{ height: 60 }}
                        onClick={"/donate-now"}
                      >
                        {t("/donate-now.page-title")}

                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                          <i className="fa fa-arrow-right" />
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Donate End */}
    </>
  );
}
