import Header from "../components/Header";
import { useTranslation, withTranslation, Trans } from "react-i18next";

export default function Service() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <Header />
      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 500 }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              {t("servicesPage.sawli_foundation")}{" "}
            </div>
            <h1 className="display-6 mb-5">{t("servicesPage.heading1")}</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-12 col-md-12 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <div className="row">
                  <div className="col-md-3 col-lg-3  align-items-center">
                    <img
                      className="img-fluid mb-4"
                      src="assets/img/icon-1.png"
                      alt=""
                    />
                    <h4 className="mb-3">{t("servicesPage.h1")}</h4>

                    <a className="btn btn-outline-primary px-3" href="">
                      {t("servicesPage.Learn_More")}
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <p className="mb-4" align="justify">
                      <strong> {t("servicesPage.services_h1_1")}</strong>
                      {t("servicesPage.services_p1_1")}
                      <br />

                      <strong> {t("servicesPage.services_h1_2")}</strong>
                      {t("servicesPage.services_p1_2")}
                      <br />

                      <strong> {t("servicesPage.services_h1_3")}</strong>
                      {t("servicesPage.services_p1_3")}
                      <br />

                      <strong> {t("servicesPage.services_h1_4")}</strong>
                      {t("servicesPage.services_p1_4")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <div className="row">
                  <div className="col-md-9 col-lg-9  align-items-center">
                    <p className="mb-4" align="justify">
                      <strong>{t("servicesPage.services_h2_1")}</strong>
                      {t("servicesPage.services_p2_1")}
                      <br />

                      <strong>{t("servicesPage.services_h2_2")}</strong>
                      {t("servicesPage.services_p2_2")}
                      <br />

                      <strong>{t("servicesPage.services_h2_3")}</strong>
                      {t("servicesPage.services_p2_3")}
                      <br />

                      <strong>{t("servicesPage.services_h2_4")}</strong>
                      {t("servicesPage.services_p2_4")}
                      <br />

                      <strong>{t("servicesPage.services_h2_5")}</strong>
                      {t("servicesPage.services_p2_5")}
                    </p>
                  </div>
                  <div className="col-md-3 col-lg-3  align-items-center">
                    <img
                      className="img-fluid mb-4"
                      src="assets/img/icon-2.png"
                      alt=""
                    />
                    <h4 className="mb-3">
                      {" "}
                      {t("servicesPage.services_heding2")}{" "}
                    </h4>
                    <a className="btn btn-outline-primary px-3" href="">
                      {t("servicesPage.Learn_More")}

                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <div className="row">
                  <div className="col-md-3 col-lg-3  align-items-center">
                    <img
                      className="img-fluid mb-4"
                      src="assets/img/icon-1.png"
                      alt=""
                    />
                    <h4 className="mb-3">
                      {" "}
                      {t("servicesPage.services_heding3")}{" "}
                    </h4>
                    <a className="btn btn-outline-primary px-3" href="">
                      {t("servicesPage.Learn_More")}

                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <p className="mb-4" align="justify">
                      <strong> {t("servicesPage.services_h3_1")}</strong>
                      {t("servicesPage.services_p3_1")}
                      <br />

                      <strong> {t("servicesPage.services_h3_2")}</strong>
                      {t("servicesPage.services_p3_2")}
                      <br />

                      <strong> {t("servicesPage.services_h3_3")}</strong>
                      {t("servicesPage.services_p3_3")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <div className="row">
                  <div className="col-md-9 col-lg-9  align-items-center">
                    <p className="mb-4" align="justify">
                      <strong>{t("servicesPage.services_para4")}</strong>
                      {t("servicesPage.services_p4")}
                      <br />

                      <strong> {t("servicesPage.services_h4")} </strong>
                      {t("servicesPage.services_p44")}
                      <br />

                      <strong>{t("servicesPage.services_h4_1")}</strong>
                      {t("servicesPage.services_p4_1")}
                      <br />

                      <strong>{t("servicesPage.services_h4_2")}</strong>
                      {t("servicesPage.services_p4_2")}
                    </p>
                  </div>
                  <div className="col-md-3 col-lg-3  align-items-center">
                    <img
                      className="img-fluid mb-4"
                      src="assets/img/icon-2.png"
                      alt=""
                    />

                    <h4 className="mb-3">
                      {" "}
                      {t("servicesPage.services_heding4")}{" "}
                    </h4>
                    <a className="btn btn-outline-primary px-3" href="">
                      {t("servicesPage.Learn_More")}

                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <div className="row">
                  <div className="col-md-3 col-lg-3  align-items-center">
                    <img
                      className="img-fluid mb-4"
                      src="assets/img/icon-1.png"
                      alt=""
                    />
                    <h4 className="mb-3">
                      {t("servicesPage.services_heding5")}
                    </h4>
                    <a className="btn btn-outline-primary px-3" href="">
                      {t("servicesPage.Learn_More")}

                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <p className="mb-4" align="justify">
                      <strong>{t("servicesPage.services_para5")}</strong>
                      {t("servicesPage.services_p5")}
                      <br />
                      <strong> {t("servicesPage.services_h5")}</strong>
                      {t("servicesPage.services_p55")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <div className="row">
                  <div className="col-md-9 col-lg-9  align-items-center">
                    <p className="mb-4" align="justify">
                      <strong>{t("servicesPage.services_para6")} </strong>
                      {t("servicesPage.services_p6")}
                      <br /> <strong>{t("servicesPage.services_h6")} </strong>
                      {t("servicesPage.services_p66")}
                    </p>
                  </div>
                  <div className="col-md-3 col-lg-3  align-items-center">
                    <img
                      className="img-fluid mb-4"
                      src="assets/img/icon-2.png"
                      alt=""
                    />
                    <h4 className="mb-3">
                      {" "}
                      {t("servicesPage.services_heding6")}{" "}
                    </h4>
                    <a className="btn btn-outline-primary px-3" href="">
                      {t("servicesPage.Learn_More")}

                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
    </>
  );
}
