import Header from "../components/Header";
import { Link } from "react-router-dom";

import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

export default function PageNotFound() {
  const pathname = window.location.pathname;
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
           {t('page-not-found.404-error')}
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
               {t('page-not-found.404-error')}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* 404 Start */}
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <i className="bi bi-exclamation-triangle display-1 text-primary" />
              <h1 className="display-1">{t('page-not-found.404')}</h1>
              <h1 className="mb-4">{t('page-not-found.pagenotfound')}</h1>
              <p className="mb-4">
                {t('page-not-found.error-message')}
              </p>
              <a className="btn btn-outline-primary py-2 px-3" href="">
                {t('page-not-found.back-to-home')}
                <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                  <i className="fa fa-arrow-right" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* 404 End */}
    </>
  );
}
